* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: background 0.5s ease-in-out;
}

body {
    /* background-color: #282e38; */
    /* color: #adb5bd; */
    /* overflow-x: hidden; */
    font-family: sans-serif;
    -webkit-tap-highlight-color: transparent;
}

textarea:focus,
input:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

.site-holder {
    display: inline-block;
    /* overflow-x: unset; */
    width: 100%;
    height: auto;
    /* position: relative; */
}

.main-cont {
    width: 100%;
    display: block;
    float: left;
    height: 100%;
    padding: 30px 0 5px 0;
}

.header {
    background: #2e97d3;
    clear: both;
    /* position: sticky;
    top: 0;
    z-index: 9; */
    /* width: 100vw; */
    height: 60px;
    /* padding: 0px 5px; */
    box-shadow: 1px 5px 30px 3px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    margin-bottom: 10px;
}

.buttons {
    text-align: right;
    margin-bottom: 10px;
}

.buttons button {
    margin-right: 2px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}


/* Header Tab CSS */

.tabs-container .tabs {
    background: rgb(40, 44, 49);
    transition: 0.3s;
    margin-right: 5px;
    /* margin-top: 10px; */
}


/* Header Tab CSS ends  */

.tabs-container .tabs:hover {
    background: rgb(50, 58, 70);
}

.tabs-container .tabs:last-child {
    margin-right: 0px;
}

#heading {
    color: #fff;
    float: left;
    width: 50%;
    font-weight: bold;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    padding: 0px 10px;
    height: 60px;
    display: flex;
    justify-content: left;
    align-items: center;
}

#heading span {
    margin-left: 34px;
}

.navbar-2 {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 115px;
}

.navbar-2 li:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* .navbar-2 li {
    display: inline-block;
} */

#switch-theme {
    float: right;
    width: 50%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar li {
    width: 100%;
    padding: 6px 0px;
}

.navbar-icons {
    font-size: 1.4rem;
    padding-right: 11px;
}

#mobileMenu {
    width: 100%;
    display: flex;
    padding-right: 25px;
    justify-content: flex-end;
}

#mob-dark-mode {
    display: none;
}

#pc-dark-mode {
    display: flex;
    align-items: center;
}

.current-number-container {
    float: left;
    margin: 0 auto;
    width: 60%;
}

.map-and-tf-container {
    float: right;
    width: 40%;
    display: flex;
    /* align-items: center; */
}

.last-updated-at {
    width: 100%;
    text-align: left;
    padding-left: 55px;
    padding-top: 0px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    animation: animate-last-up ease-in-out 1.2s;
}

@keyframes animate-last-up {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.current-cont {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.total-numbers {
    width: 20%;
    float: left;
    padding: 15px;
    margin: 10px 10px;
    border-radius: 5px;
    transition: box-shadow 0.4s ease-in-out;
    /* box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.5); */
    /* box-shadow: 7px 7px 15px 1px rgba(0, 0, 0, 0.5); */
    /* animation: animate-box ease-in-out 1s; */
}

.conf-no {
    animation: animate-box-conf ease-in-out 0.6s;
}

.rec-no {
    animation: animate-box-rec ease-in-out 0.6s;
}

.dth-no {
    animation: animate-box-dth ease-in-out 0.6s;
}

.act-no {
    animation: animate-box-act ease-in-out 0.6s;
}

@keyframes animate-box-conf {
    from {
        transform: translate(-40%, 40%);
    }
    to {
        transform: translate(0%, 0%);
    }
}

@keyframes animate-box-rec {
    from {
        transform: translate(40%, 40%);
    }
    to {
        transform: translate(0%, 0%);
    }
}

@keyframes animate-box-dth {
    from {
        transform: translate(-40%, -40%);
    }
    to {
        transform: translate(0%, 0%);
    }
}

@keyframes animate-box-act {
    from {
        transform: translate(40%, -40%);
    }
    to {
        transform: translate(0%, 0%);
    }
}

#number-details-heading {
    font-weight: bold;
    font-size: 1em;
    letter-spacing: 0.2px;
    width: 100%;
    text-align: center;
    margin: 0px 0px;
}

.moer-details {
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-wrap: wrap;
    clear: both;
    width: 100%;
    height: 100%;
    padding-top: 2px;
}

.no-details-left {
    margin: 10px 0px;
    width: 100%;
}


/* Footer CSS Starts*/

.footer {
    clear: both;
    width: 100%;
    height: 52px;
    background: #1c1c1c;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    bottom: 0px;
    left: 0;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
}

.footer-lang {
    display: flex;
    font-size: 14px;
    justify-content: center;
}

.footer-lang .langs {
    margin: 0px 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
}

.footer-left {
    float: left;
    width: 50%;
    color: #adadad;
    font-size: 11px;
    text-align: center;
}

.footer-right {
    float: right;
    cursor: pointer;
    width: 50%;
    font-size: 12px;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
}


/* .navbar-footer {
    display: none;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: center;
}

.navbar-footer li {
    list-style-type: none;
    display: inline-block;
    padding: 0px 10px;
    color: rgb(255, 199, 199);
    text-shadow: 2px 1px 3px rgb(160, 58, 27);
}

.navbar-footer a:hover {
    transition-delay: 0.2s;
    color: rgb(164, 255, 243);
    transition-timing-function: ease-in-out;
} */

a {
    text-decoration: none;
    color: unset;
}

.footer-right span a {
    color: #d1cfcf;
    text-decoration: none;
}

.footer-right span a:hover {
    text-decoration: underline;
}


/* Footer CSS Ends */

.no-details-right {
    width: 100%;
    text-align: center;
    margin: 10px 0px;
    font-size: 1.4em;
    font-weight: bold;
}

.footer h5 {
    margin-top: 18px;
    color: #adadad;
}

.site-holder {
    height: 100%;
    /* display: inline-block; */
}

.no-details-right {
    width: 100%;
    text-align: center;
    margin: 10px 0px;
    font-size: 1.4em;
    font-weight: bold;
}

.today-delta {
    height: 30px;
    font-size: 12px;
    /* color: rgb(34, 143, 106); */
    text-align: center;
}

#rate-percent {
    height: fit-content;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
    padding: 4px;
    border-radius: 10px;
    font-size: 12px;
}

.progress-bar {
    width: 100px;
    margin: 0 auto;
}

.state-table-container {
    /* width: 100%; */
    /* display: flex; */
    /* transform: translateX(30%); */
    /* max-width: 840px; */
    margin-bottom: 100px !important;
}


/* Samples Tested CSS Starts */

.sample-tf-cont {
    display: flex;
    margin-bottom: 40px;
}

.sample-tested-main {
    margin-top: 10px;
    margin-bottom: 15px;
    width: 50%;
    display: flex;
    flex-direction: row;
}

.samples-tested-container {
    padding-left: 50px;
    text-align: center;
}

.sample-tested-title {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.2px;
    text-align: left;
    /* padding-left: 10px; */
}

.sample-tested-title span {
    /* border-bottom: 3px solid #01b0e6;
    padding-bottom: 2px; */
}

.samples-tested {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sample-box {
    width: 160px;
    height: 170px;
    background: #323a46;
    border-radius: 5px;
    /* box-shadow: 7px 7px 15px 1px rgba(0, 0, 0, 0.4); */
    /* margin: 10px 10px; */
}

.tested {
    margin: 10px 10px 0px 0px;
}

.positive {
    margin: 10px 0px 0px 10px;
}

.last-updated-time {
    font-size: 11px;
    margin: 5px 0px 0px 0px;
    text-align: left;
    font-weight: bold;
}

.samples-tested>div div:nth-child(1) {
    height: 50px;
    padding-top: 15px;
    letter-spacing: 0.3px;
    font-size: 17px;
    font-weight: bold;
}

.test-rate {
    height: 35px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.samples-tested>div div:nth-child(3) {
    height: 50px;
    padding-top: 15px;
    padding-bottom: 30px;
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: bold;
}

.sample-box div:nth-child(3) {
    display: flex;
    padding-top: 5px;
    justify-content: center;
}

.tested hr {
    border: 2.5px solid #2ab407;
    width: 80%;
    margin: 15px auto;
    border-radius: 4px;
}

.positive hr {
    border: 2.5px solid #01b0e6;
    width: 80%;
    margin: 15px auto;
    border-radius: 4px;
}


/* Samples Tested CSS Ends  */


/* Top Five States  */

.top-five-container {
    width: 50%;
    margin-top: 10px;
}

.top-five-main {
    width: 92%;
    margin: 0px auto 15px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* border: 1px solid red; */
}

.tf-title {
    text-align: center;
    font-size: 19px;
    margin-bottom: 5px;
    font-family: sans-serif;
    font-weight: bold;
}

.tf-title span {
    /* border-bottom: 3px solid #01b0e6; */
}

.tfs-table {
    border-collapse: collapse;
    margin-top: 6px;
    border-radius: 5px;
    /* box-shadow: 7px 7px 15px 1px rgba(0, 0, 0, 0.5); */
    /* border: 0.7px solid #1c1c1c; */
}

.tfs-table thead,
tbody {
    width: 100%;
}

.tfs-table tr th:nth-child(1),
.tfs-table tr td:nth-child(1) {
    width: 30px;
    text-align: center;
    padding: 0;
}

.tfs-table tr th:nth-child(2),
.tfs-table tr td:nth-child(2) {
    text-align: left;
    padding: 0;
}

.tfs-table tr th:nth-child(3),
.tfs-table tr td:nth-child(3) {
    text-align: center;
    padding-right: 5px;
}

.tfs-table tr th,
.tfs-table tr td {
    width: 140px;
    text-align: right;
    padding: 4px;
}


/* Top Five States Ends  */


/* Donation CSS Starts  */

.donation-container {
    width: 100%;
    margin-top: -10px;
}

.donation-subcontainer {
    width: 100%;
    padding-top: 1px;
}

.taglines-container {
    width: 100%;
    margin: 0 auto;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 1px 2px 3px 1px #00000073;
    letter-spacing: 0.5px;
    font-size: 15px;
    /* background: linear-gradient(to right, #283048, #859398); */
    padding-top: 12px;
    color: #fff;
    height: 50px;
    text-align: center;
}

.donate-now {
    margin-top: 30px;
    width: 100%;
}

.donate-now-title {
    width: 93%;
    text-align: center;
    margin: 10px auto;
}

.donate-now-title p {
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    margin-top: 8px;
}


/* .donate-now-title span {
    border: 0.6px solid #ccc;
    font-size: 20px;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 1px 2px 3px 1px rgba(78, 48, 48, 0.445);
} */

.donate-using-upi {
    margin: 10px 0px;
    width: 100%;
}

.donate-using-upi div {
    width: 93%;
    margin: 0 auto;
}

.donate-using-upi div:nth-child(1) {
    text-align: center;
    padding: 10px 0px 6px 0px;
}

.donate-using-upi div:nth-child(2) {
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

#donate-btn {
    border: none;
    height: 42px;
    padding: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #fff;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    background: linear-gradient(to right, #DA22FF, #9733EE);
    outline: none;
}

#bank-details {
    padding: 20px;
    font-size: 12px;
}

.bnk-title {
    font-weight: bold;
    font-size: 13px;
}


/* State District Table CSS  */

.state-dist-table thead {
    display: block;
}

.state-dist-table thead tr th {
    text-align: left;
    padding: 5px 0px;
}

.state-dist-table tbody td:nth-child(2) {
    text-align: left;
    width: unset !important;
}

.state-dist-table thead tr th {}

.state-dist-table tbody td:nth-child(3) {
    text-align: center !important;
}

.state-dist-table tbody {
    display: block;
    height: 445px;
    overflow-y: scroll;
}

.table-containers {
    width: 90%;
    margin: 0 auto;
}

.all-table-cont {
    display: flex;
    flex-wrap: wrap;
}

.zone-table {
    width: 40%;
}

.zone-table table {
    border-collapse: collapse;
}

.zone-table table>tr {
    /* background: red; */
}

.zone-table table>tr td {
    display: table-cell;
}

.zone-table table tr th,
.zone-table table tr td {
    width: 100px !important;
}

.zone-table table tr th:nth-child(2) {
    text-align: left;
}

.zone-table table tr td:nth-child(2) {
    text-align: left;
}

.zone-table table tr td:nth-child(3) {
    text-align: left;
}

.zone-table table tr td {
    width: 100px !important;
}


/* Typing Animation */

.styles_typicalWrapper__1_Uvh {
    position: absolute;
    font-weight: 480;
    top: 7px;
    left: 7px;
    color: rgb(117, 117, 117);
}


/* State District Table CSS Ends  */

@media(max-width:768px) {
    /*.tabs-container, .main-cont{ display: none !important;}
    html, body, #root, .site-holder{ width:100%; float: left; display: inline-block;}
    .header{ height: 100px;}
    #heading{ width:100%; margin-bottom:10px; }    
    #switch-theme{ margin: 0 auto; float: left; padding-left: 0px; display: inline-block; margin-bottom:20px; }
    .current-number-container{ width: 100%; margin-bottom: 20px; }
    .total-numbers{ width: 100%; margin:0px 0 20px 0; padding:0px; }
    .plot-cont{ width:100%; max-width: 100%;}
    .state-details-cont{ height: auto !important; padding-bottom:0px; width: 100% !important;}
    .state-details .state-det-1{ float: left; }
    .map-container{ width: 100% !important; }
    .header, .footer, .map-container svg{ width: 100%;}
    .footer{ position: relative; }*/
    .main-cont {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .current-number-container {
        width: 100%;
        text-align: center;
    }
    .map-and-tf-container {
        width: 100%;
    }
    .current-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .total-numbers {
        width: 135px;
    }
    .sample-tf-cont {
        display: block;
    }
    .last-updated-at {
        padding-left: 41px;
    }
    .last-updated-time {
        text-align: center;
    }
    .sample-box {
        width: 130px;
    }
    .sample-tested-title {
        text-align: center;
    }
    .sample-tested-main {
        padding: 0px;
    }
    #add-to-homescreen {
        /* padding-right: 18px; */
    }
    #mob-dark-mode {
        display: flex;
        align-items: center;
        /* height: 100px; */
        /* padding: 6px 20px; */
        font-size: 25px;
        font-weight: bold;
        /* padding-right: 18px; */
        letter-spacing: 2.5px;
        text-transform: uppercase;
    }
    #mob-dark-mode i {
        /* margin-right: 6.5px; */
    }
    #pc-dark-mode {
        /* display: none; */
        /* padding-right: 18px; */
    }
}

@media(max-width:768px) {
    #heading span {
        margin-left: 2px;
    }
    /* .navbar-footer {
        display: block;
    } */
    .footer-left {
        margin-top: 0px;
    }
    .footer-right {
        margin-top: 0px;
    }
    .sample-tested-main {
        flex-direction: column;
        width: 100%;
    }
    .samples-tested-container {
        width: 100%;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 14px;
    }
    .top-five-container {
        width: 100%;
    }
    .top-five-main {
        justify-content: center;
    }
    .tf-title {
        text-align: center;
        width: 100%;
    }
    .table-containers {
        width: 96%;
        margin: 0 auto;
    }
    state-dist-table thead tr th:nth-child(2) {
        /* width: 120px !important; */
    }
    .zone-table {
        width: 95%;
        margin: 0 auto;
    }
}


/* @media all and(display-mode:standalone) {
    #add-to-homescreen {
        display: none;
    }
} */