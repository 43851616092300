.district-data-div,
.district-data-div td {
    width: 100% !important;
}

th {
    font-weight: bold !important;
}

.internal-table {
    width: 100%;
    float: left;
    border-collapse: collapse;
    text-align: left;
}

.label-div,
.data-div {
    text-align: left;
    width: 100%;
}

.data-div td:nth-child(1) {
    width: 10px !important;
    display: inline-block;
}

.data-div td:nth-child(2) {
    width: 100px !important;
    /* display: inline-block; */
}

.label-div th:nth-child(1) {
    width: 10px !important;
}

.label-div th:nth-child(2) {
    text-align: left !important;
    width: 100px !important;
    /* padding-left: 18px; */
}

.label-div th {
    width: 90px !important;
    /* padding-left: 20px !important; */
    text-align: center !important;
}

.data-div td {
    width: 90px !important;
    /* padding-left: 20px !important; */
    font-weight: normal !important;
    text-align: center !important;
}

.data-div td:nth-child(2) {
    text-align: left !important;
    /* width: 100px !important; */
}


/* Input Typewriter Effect */

.styles_typicalWrapper__1_Uvh {
    position: absolute;
    font-weight: 480;
    top: 7px;
    left: 7px;
    color: rgb(117, 117, 117);
}

@media (max-width:600px) {
    .label-div th {
        width: 70px !important;
    }
    .label-div th:nth-child(5) {
        /* width: 90px !important; */
        text-align: left !important;
    }
    .data-div td:nth-child(1) {
        width: 10px !important;
    }
    .data-div td:nth-child(2) {
        width: fit-content !important;
        text-align: left !important;
    }
    .data-div td:nth-child(5) {
        width: fit-content !important;
        text-align: right !important;
        padding-left: 15px;
    }
    .data-div td {
        text-align: center !important;
    }
}