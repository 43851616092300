.tabs-container {
    height: 45px;
    /* position: sticky; */
    /* top: 0; */
    display: flex;
    justify-content: center;
    margin: 30px 50px 0 50px;
}

.tabs {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 33.33%;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.navbar {}

.navbar li {
    list-style-type: none;
}

.navbar li:hover {
    transition-delay: 0.25s;
    color: rgb(136, 248, 224);
    transition-timing-function: ease-in-out;
}

.navbar li:nth-child(1) {}

.navbar li:nth-child(2) {}

.tabs:hover {
    cursor: pointer;
}

@media(max-width:600px) {
    .tabs-container {
        margin: 15px 5px 0 5px;
    }
    /* .navbar {
        display: none;
    } */
}