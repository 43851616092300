            .plot-cont {
                width: 100%;
                /* margin: 0 auto; */
                display: inline-block;
                padding-left: 20px;
                flex-direction: row;
                flex-wrap: wrap;
                /* float: left; */
            }
            
            .map-container {
                /* display: block; */
                justify-content: center;
                height: auto;
                width: 45%;
                float: left;
            }
            
            .plot-cont-heading {
                width: 100%;
                /* color: #fff; */
                font-weight: bold;
                text-align: center;
                margin: 5px auto;
                font-size: 1.3em;
            }
            
            .plot-cont-heading span {
                /* padding: 3px 0px;
                border-bottom: 3px solid #01b0e6; */
            }
            
            .path-region:hover {
                stroke: rgb(255, 0, 119);
                fill: rgb(255, 6, 172);
            }
            
            #india-map {
                width: 100vw;
                height: 450px;
            }
            /* State Details Container */
            
            .state-details-cont {
                /* padding-bottom: 20px; */
                width: 120px;
                height: 370px;
                float: left;
                display: flex;
                text-align: center;
                justify-content: center;
                flex-direction: column;
            }
            
            .state-name {
                font-weight: bold;
                font-family: sans-serif;
                padding: 0px 5px;
                font-size: 14px;
                height: auto;
                text-align: center;
            }
            
            .state-details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                padding-top: 5px;
                margin-left: 5px;
                align-items: left;
            }
            
            .state-det-1 {
                border: 0.4px solid rgb(43, 42, 42);
                width: 63px;
                height: 65px;
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.4);
                text-align: center;
                margin: 0px;
            }
            
            .status-name {
                text-align: center;
                padding: 0px 0px;
            }
            
            .status-val {
                text-align: center;
                padding: 0px 0px;
            }
            /* State Details Container End */
            /* TABLE CSS START */
            
            .state-table-container {
                width: 100%;
                margin: 0px auto;
                padding: 30px 0 0px 0;
                display: flex;
                flex-direction: column;
            }
            
            .state-table-container .affectedDiv {
                text-align: center;
                margin: 15px 0 0px 0;
                font-size: 15px;
            }
            
            .state-table-container table thead,
            .state-table-container table tbody {
                font-size: 12px;
                margin: 0 auto;
                text-align: center;
            }
            
            .state-table-header {
                width: 100%;
            }
            
            .table-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
            
            .state-table {
                font-family: sans-serif;
                border-collapse: collapse;
                border-radius: 5px;
                margin: 5px auto;
            }
            
            .state-table td,
            .state-table th {
                padding: 5px;
                font-size: 12px;
            }
            
            td {
                border: solid 1px transparent;
                border-style: solid none;
                border-radius: 0px;
            }
            
            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6) {
                text-align: right;
            }
            
            .state-table td,
            .state-table th {
                display: inline-block;
                text-align: center;
                width: 120px;
                font-size: 14px;
            }
            
            .state-table td:nth-child(1),
            .state-table th:nth-child(1) {
                width: 20px;
            }
            
            .state-table thead tr:first-child {
                /* background: rgb(50, 58, 70) !important; */
                border-top-left-radius: 3px;
                /* color: #fff !important; */
            }
            
            .state-table thead tr th {
                font-weight: 400;
            }
            
            .state-table td:nth-child(2),
            .state-table th:nth-child(2) {
                width: 200px;
                text-align: left;
            }
            
            .state-table tbody tr:nth-child(2n+2) {
                background-color: #1c1c1c;
                /* background-color: rgb(177, 145, 145) !important; */
            }
            
            .state-table tr:hover {
                /* background-color: rgb(136, 134, 134) !important; */
                cursor: pointer;
            }
            
            .state-table td:nth-child(1) {
                font-weight: bold;
                ;
            }
            /* Table CSS END */
            /* Pie Chart AFFECTED AGE GROUP START */
            
            .graphs-main-cont {
                width: 100%;
            }
            
            .graphs-holder {
                width: 92.5%;
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 30px 0 50px 0;
            }
            
            .graph-container {
                width: 50%;
                padding: 0px 5px;
            }
            
            .affected-age-group {
                width: 100%;
            }
            
            .death-rec-container {
                /* width: 50%; */
                padding: 0px 5px;
            }
            
            #death-rec-title {
                width: 100%;
            }
            
            .death-vs-rec {
                width: 100%;
            }
            
            .chartjs-render-monitor {}
            
            .daily-trend-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                /* border-right: 1px solid rgb(182, 182, 182); */
            }
            
            .daily-trends {
                width: 100%;
            }
            /* State Case Chart  */
            
            .state-case-chart-cont {
                width: 50%;
            }
            
            .scc-main {
                width: 100%;
            }
            /* State CSS Starts */
            /* State CSS Ends  */
            
            @media(max-width:768px) {
                .plot-cont {
                    margin: 0 auto;
                    width: auto;
                    padding: 0px 4px;
                    max-width: 100%;
                }
                .state-table-container {
                    width: 100%;
                    margin: 0 auto;
                }
                .state-details-cont {
                    width: 20%;
                    margin-left: 0px;
                }
                .graphs-main-cont {
                    flex-direction: column;
                }
                .graphs-holder {
                    width: 98%;
                }
                .graph-container {
                    margin: 10px auto 20px auto;
                    width: 100%;
                }
                .affected-age-group {
                    width: 100%;
                }
                .daily-trend-container {
                    width: 100%;
                    border-right: none;
                }
                .death-rec-container {
                    width: 100%;
                }
                #death-rec-title {
                    width: 100%;
                }
                .death-vs-rec {
                    width: 100%;
                }
                .chartjs-render-monitor {
                    width: 703px;
                }
                /* Responsive Table CSS */
                .state-table-container {
                    transform: none;
                    padding: 0;
                }
                .state-table {
                    width: 100%;
                }
                .state-table-container table thead,
                .state-table-container table tbody {
                    display: table;
                    font-size: 10px !important;
                    /* margin: 0 auto; */
                    /* text-align: center; */
                    transform: none;
                }
                .state-table td,
                .state-table th {
                    display: table-cell;
                    text-align: right;
                }
                .state-table thead tr th {
                    font-size: 11px;
                    text-align: center;
                    /* border-right: 0.5px solid rgb(160, 31, 31); */
                }
                .state-table thead tr {
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    -webkit-tap-highlight-color: transparent;
                    width: 100%;
                }
                .state-table thead tr th:nth-child(1) {
                    width: 5%;
                    /* padding-left: 20px; */
                    border-right: none;
                    /* background: #262626; */
                }
                .state-table thead tr th:nth-child(2) {
                    width: 32%;
                    padding-left: 4px;
                    text-align: left;
                }
                /* .state-table thead tr th:nth-last-child(1) {
                    padding-right: 2px;
                    text-align: right;
                } */
                .table-container {
                    width: 100%;
                }
                .state-table-header {
                    width: 100%;
                }
                .state-table tbody tr td:nth-child(1) {
                    width: 5%;
                }
                .state-table tbody tr td:nth-child(2) {
                    width: 32%;
                }
                .state-table tbody tr td {
                    text-align: center;
                }
                .state-table thead tr th {
                    text-align: right;
                    padding: 4px 0px;
                    width: 100vw;
                    height: 38px;
                }
                .state-case-chart-cont {
                    width: 100%;
                }
                .scc-main {
                    width: 100%;
                }
            }