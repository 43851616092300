.about-content {
    display: block;
    padding: 40px 40px 30px 40px;
    text-align: left;
}

.about-content p {
    font-size: 18px;
    text-align: left;
    margin-bottom: 0px;
    width: 100%;
    font-family: sans-serif;
    display: inline-block;
}

.about-content ul {
    margin-left: 50px;
    margin-bottom: 0px;
    margin-top: 0px;
}